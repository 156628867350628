<template>
  <div class="equipmentInfo">
    <div class="detail">
      <div class="info">
        <div class="infoBan">
          <img src="../img/bgb.png" alt="" />
        </div>
        <!-- <div class="info-l">
          <img src="./img/test.png" alt="" />
        </div> -->
        <div class="info-r">
          <p class="label">产品名称：</p>
          <p class="text">{{ equipmentInfo.productName }}</p>
          <p class="label">设备位置：</p>
          <div class="textTe">
            <span>{{ equipmentInfo.spaceName || "--" }}</span>
            <div
              class="area-r"
              v-if="equipmentInfo.spaceName && equipmentInfo.spaceId && pathId"
              @click="handelNavigation"
            >
              <div class="txt">路线</div>
              <div class="img">
                <img src="../img/nav.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <!-- 设备信息 -->
        <div class="content-info">
          <div class="title">设备信息</div>
          <div class="content-info-content">
            <div class="content-info-content-t">
              <div class="label">
                <div class="label-text">名称:</div>
                <div class="label-value">{{ equipmentInfo.deviceName }}</div>
              </div>
              <div class="label">
                <div class="label-text">编号:</div>
                <div class="label-value">
                  <span id="foo">
                    {{ equipmentInfo.deviceNum }}
                  </span>
                  <button
                    class="copy"
                    data-clipboard-target="#foo"
                    @click="copy"
                  >
                    复制
                  </button>
                </div>
              </div>
            </div>
            <div class="content-info-content-b">
              <p class="label">
                <span class="label-text">厂家名称：</span>
                <span class="label-value">{{
                  equipmentInfo.supplierName || "--"
                }}</span>
              </p>
              <p class="label">
                <span class="label-text">厂家联系人：</span>
                <span class="label-value">{{
                  equipmentInfo.supplierContact || "--"
                }}</span>
              </p>
              <p class="label">
                <span class="label-text">厂家联系方式：</span>
                <span class="label-value">{{
                  equipmentInfo.supplierPhone || "--"
                }}</span>
              </p>
              <!-- <p class="label">
                <span class="label-text">安装时间：</span>
                <span class="label-value">2023-12-25</span>
              </p>
              <p class="label">
                <span class="label-text">维修周期：</span>
                <span class="label-value">30天</span>
              </p> -->
            </div>
          </div>
        </div>
        <!-- 运转信息 -->
        <div class="revolve-info">
          <div class="title">运转信息</div>
          <div class="revolve-content">
            <div class="revolve-content-l">设备状态:</div>
            <div
              class="revolve-content-r"
              :style="{
                color:
                  equipmentInfo.status == 1
                    ? '#5ad9ad'
                    : equipmentInfo.status == 2
                    ? '#fde588'
                    : '#f95c5c',
              }"
            >
              {{ equipmentInfo.status | formatStatus }}
            </div>
          </div>
        </div>
        <!-- 维保记录 -->
        <div class="maintenance-record">
          <div class="title">维保记录</div>
          <div class="maintenance-record-table">
            <table>
              <thead>
                <tr>
                  <th>维保编号</th>
                  <th>维保时间</th>
                  <th>维保人</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="maintenanceList.length > 0">
                  <tr
                    v-for="(item, index) in maintenanceList"
                    :key="index"
                    @click="handelClickWorkOrder(item.id, 1)"
                  >
                    <td class="number">
                      {{ item.serviceNumber || "--" }}
                    </td>
                    <td>{{ item.maintenanceTime || "--" }}</td>
                    <td>{{ item.maintenanceUserName }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="3">
                      <v-empty></v-empty>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="pagination">
            <van-pagination
              force-ellipses
              v-model="maintenanceParams.curPage"
              :total-items="maintenanceTotals"
              :items-per-page="maintenanceParams.pageSize"
              @change="handelChangePagination($event, 1)"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
        </div>
        <!-- 工单记录 -->
        <div class="workOrder-record">
          <div class="title">工单记录</div>
          <div class="workOrder-record-table">
            <table>
              <thead>
                <tr>
                  <th>工单编号</th>
                  <th>维休时间</th>
                  <th>维修人</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="workOrderList.length > 0">
                  <tr
                    v-for="(item, index) in workOrderList"
                    :key="index"
                    @click="handelClickWorkOrder(item.id, 2)"
                  >
                    <td class="number">
                      {{ item.serviceNumber || "--" }}
                    </td>
                    <td>{{ item.repairTime || "" }}</td>
                    <td>{{ item.repairName }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="3">
                      <v-empty></v-empty>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="pagination">
            <van-pagination
              @change="handelChangePagination($event, 2)"
              force-ellipses
              v-model="requestData.curPage"
              :total-items="totals"
              :items-per-page="requestData.pageSize"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Pagination, Icon } from "vant";
Vue.use(Pagination);
Vue.use(Icon);
import Clipboard from "clipboard";
import {
  getWorkListByDeviceSn,
  getMaintenanceListByDeviceSn,
  getBimRoadIdUrl,
  getDeviceWorkInfo,
} from "./api";
export default {
  name: "equipmentInfo",
  components: {},
  props: {},
  data() {
    return {
      currentPage: 1,
      equipmentInfo: {},
      workOrderList: [],
      maintenanceList: [],
      deviceId: "XZZX07-NT-591",
      requestData: {
        curPage: 1,
        pageSize: 5,
      },
      maintenanceParams: {
        curPage: 1,
        pageSize: 5,
      },
      deviceNum: "",
      totals: "",
      maintenanceTotals: "",
      pathId: "",
    };
  },
  filters: {
    formatStatus(value) {
      const obj = {
        1: "在线",
        2: "异常",
        0: "离线",
      };
      return obj[value];
    },
  },
  created() {
    const { deviceId, result } = this.$route.query;
    if (deviceId != undefined) {
      this.getEquipmentInfo(deviceId);
    }
    if (result != undefined) {
      const deviceId = JSON.parse(decodeURIComponent(result));
      this.getEquipmentInfo(deviceId);
    }
  },

  mounted() {},
  methods: {
    handelNavigation() {
      this.$router.push({
        name: "pathPlaning",
        query: {
          spaceId: this.pathId,
          serviceZone: this.equipmentInfo.spaceName,
          type: "worker",
        },
      });
    },
    copy() {
      let _this = this;
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        _this.$toast({ message: "复制成功", duration: 2000 });
        //  释放内存
        clipboard.destory();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持复制");
        // 释放内存
        clipboard.destory();
      });
    },
    handelClickWorkOrder(id, type) {
      if (type == 1) {
        this.$router.push({
          name: "deviceMaintenanceDetail",
          query: {
            id,
          },
        });
      } else {
        this.$router.push({
          name: "workOrderDetails",
          query: {
            id,
          },
        });
      }
    },
    //切换分页
    handelChangePagination(value, type) {
      if (type == 1) {
        this.getMaintenanceListByDeviceSn();
      } else {
        this.getWorkListByDeviceSn();
      }
    },
    //获取设备信息005b5257145b5469b594454996b55b77
    getEquipmentInfo(deviceId = "XZZX07-NT-591") {
      this.$axios
        .get(`/gateway/hc-device/iot/device/${deviceId}/detailBySn`)
        .then((res) => {
          if (res.code == 200) {
            this.equipmentInfo = res.data;
            this.deviceNum = res.data.deviceNum;
            if (this.equipmentInfo.spaceId) {
              this.getBimRoadId(this.equipmentInfo.spaceId);
            }
            // this.requestData.deviceNum = res.data.deviceNum;
            this.getWorkListByDeviceSn();
            this.getMaintenanceListByDeviceSn();
          }
        });
    },
    getBimRoadId(spaceId) {
      let params = {
        spaceId,
      };
      this.$axios.get(`${getBimRoadIdUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          this.pathId = res.data;
        }
      });
    },
    //获取工单记录
    getWorkListByDeviceSn() {
      this.$axios
        .get(getWorkListByDeviceSn, {
          params: { deviceNum: this.deviceNum, ...this.requestData },
        })
        .then((res) => {
          if (res.code == 200) {
            const { records, total } = res.data;
            this.totals = total;
            this.workOrderList = records;
          }
        });
    },
    //获取维保记录
    getMaintenanceListByDeviceSn() {
      this.$axios
        .get(getMaintenanceListByDeviceSn, {
          params: { deviceNum: this.deviceNum, ...this.maintenanceParams },
        })
        .then((res) => {
          if (res.code == 200) {
            const { records, total } = res.data;
            this.maintenanceTotals = total;
            this.maintenanceList = records;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.equipmentInfo {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f8f8f8;
  .detail {
    width: 100%;
    box-sizing: border-box;
    .info {
      background-image: url("./img/bg-icon.png");
      background-repeat: no-repeat;
      background-size: 100% 122%;
      padding: 78px 36px 20px 60px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      gap: 40px;
      align-items: center;
      .infoBan {
        width: 172px;
        height: 154px;
        position: absolute;
        right: 36px;
        bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-l {
        flex-shrink: 0;
        width: 270px;
        height: 270px;
        overflow: hidden;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .info-r {
        p {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        .label {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
        }
        .text {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 56px;
        }
      }
      .textTe {
        flex: 1;
        display: flex;
        span {
          width: 500px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 56px;
        }
      }
      .area-r {
        width: 148px;
        height: 60px;
        box-sizing: border-box;
        position: relative;
        z-index: 9;
        display: flex;
        align-items: center;
        background: #fff;
        padding: 10px 20px;
        border-radius: 40px;
        .txt {
          font-size: 28px;
          font-weight: 400;
          color: #fe4416;
          line-height: 40px;
          margin-right: 10px;
        }
        .img {
          width: 40px;
          height: 40px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .content {
      padding: 0 30px 24px 30px;
      width: 100%;
      box-sizing: border-box;
      .content-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff;
        border-radius: 16px;
        padding: 38px 40px 48px 38px;
        box-sizing: border-box;
        .title {
          margin-bottom: 20px;
        }
        .content-info-content {
          .content-info-content-t {
            margin-bottom: 20px;
            // display: flex;
            // align-items: flex-start;
            // justify-content: space-between;
            padding: 0 48px 0 32px;
            box-sizing: border-box;
            // padding-bottom: 20px;
            position: relative;
            .label {
              display: flex;
              align-items: flex-start;
              width: 100%;
              text-align: left;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 56px;
              &:last-child {
                display: flex;
                .copy {
                  flex-shrink: 0;
                  color: rgba(0, 126, 255, 1);
                  background-color: transparent;
                }
              }

              .label-text {
                flex-shrink: 0;
              }
              .label-value {
                // text-overflow: ellipsis;
                // overflow: hidden;
                // white-space: nowrap;
                word-wrap: break-word;
                word-break: break-all;
                padding-left: 15px;
                color: rgba(0, 0, 0, 0.9);
              }
            }
            .line {
              left: 50%;
              transform: translateX(-50%);
              position: absolute;
              width: 2px;
              height: 100%;
              background-color: #ebebeb;
            }
          }
          .content-info-content-b {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px 48px 32px 32px;
            width: 100%;
            // min-height: 416px;
            background: #fbfafa;
            border-radius: 16px;
            box-sizing: border-box;
            .label {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              line-height: 56px;
              .label-value {
                color: rgba(0, 0, 0, 0.9);
              }
            }
          }
        }
      }
      .revolve-info {
        width: 100%;
        box-sizing: border-box;
        padding: 38px 40px 36px 38px;
        margin-top: 22px;
        min-height: 206px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 20px;

        .revolve-content {
          margin-top: 20px;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          .revolve-content-l {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 56px;
          }
          .revolve-content-r {
            margin-left: 60px;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #00cb68;
            line-height: 56px;
          }
        }
      }
      .maintenance-record {
        margin-bottom: 20px;
        width: 100%;
        background: #ffffff;
        border-radius: 16px;
        padding: 38px 40px 48px 38px;
        box-sizing: border-box;
        .maintenance-record-table {
          height: 410px;
          box-sizing: border-box;
          padding: 0 10px 20px 10px;
          margin-top: 18px;
          width: 100%;
          overflow: hidden;
          overflow-y: scroll;
          background: #fbfafa;
          border-radius: 16px;
          table {
            table-layout: fixed;
            width: 100%;
            thead {
              z-index: 9;
              background: #fbfafa;
              position: sticky;
              top: 0;
              th {
                height: 76px;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                line-height: 76px;
                &:first-child {
                  width: 38%;
                }
                &:nth-child(2) {
                  width: 42%;
                }
                &:nth-child(3) {
                  width: 20%;
                }
              }
            }
            tr {
              width: 100%;
              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }

            td {
              word-wrap: break-word;
              word-break: break-all;
              height: 64px;

              text-align: center;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.9);
              line-height: 30px;
            }
            .number {
              color: #4b85fe;
              cursor: pointer;
            }
          }
        }
        .pagination {
          margin-top: 30px;
          box-sizing: border-box;
          // ::v-deep .van-pagination {
          //   li {
          //     width: 32px;
          //     height: 32px;
          //     border-radius: 8px;

          //     &::after {
          //       content: none;
          //     }
          //     &.van-pagination__item {
          //          color: rgba(0, 0, 0, 0.85);
          //     }
          //     &.van-pagination__item--active {
          //       background: #fe4416;
          //     }
          //   }

          //   .van-pagination__item:active {
          //     color: #fff;
          //     background-color: #fe4416;
          //   }
          // }
        }
      }
      .workOrder-record {
        width: 100%;
        background: #ffffff;
        border-radius: 16px;
        padding: 38px 40px 48px 38px;
        box-sizing: border-box;
        .workOrder-record-table {
          height: 410px;
          box-sizing: border-box;
          padding: 0 10px 20px 10px;
          margin-top: 18px;
          width: 100%;
          overflow: hidden;
          overflow-y: scroll;
          background: #fbfafa;
          border-radius: 16px;
          table {
            table-layout: fixed;
            width: 100%;
            thead {
              z-index: 9;
              background: #fbfafa;
              position: sticky;
              top: 0;
              th {
                height: 76px;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                line-height: 76px;
                &:first-child {
                  width: 38%;
                }
                &:nth-child(2) {
                  width: 42%;
                }
                &:nth-child(3) {
                  width: 20%;
                }
              }
            }
            tr {
              width: 100%;
              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }

            td {
              word-wrap: break-word;
              word-break: break-all;
              height: 64px;
              text-align: center;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.9);
              line-height: 30px;
            }
            .number {
              color: #4b85fe;
              cursor: pointer;
            }
          }
        }
        .pagination {
          margin-top: 30px;
          box-sizing: border-box;
          // ::v-deep .van-pagination {
          //   li {
          //     width: 32px;
          //     height: 32px;
          //     border-radius: 8px;

          //     &::after {
          //       content: none;
          //     }
          //     &.van-pagination__item {
          //          color: rgba(0, 0, 0, 0.85);
          //     }
          //     &.van-pagination__item--active {
          //       background: #fe4416;
          //     }
          //   }

          //   .van-pagination__item:active {
          //     color: #fff;
          //     background-color: #fe4416;
          //   }
          // }
        }
      }
    }
  }
  .title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    line-height: 56px;
  }
}
</style>
